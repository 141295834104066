
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { getAuth, User } from 'firebase/auth';

export default class ProfileBox extends Vue {
  user: User|null = null;

  token: string|null = null;

  refreshToken: string|null = null;

  toast = useToast();

  generateToken() {
    this.token = this.refreshToken;
  }

  onCopy() {
    this.toast.success('Copied token');
  }

  onError() {
    this.toast.error('Failed to copy token');
  }

  created() {
    getAuth(this.$firebase).onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.refreshToken = user.refreshToken;
      }
    });
  }
}
