
import { Options, Vue } from 'vue-class-component';
import ProfileBox from '@/components/ProfileBox.vue';

@Options({
  components: {
    ProfileBox,
  },
})
export default class Profile extends Vue {}
