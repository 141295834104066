<template>
  <div class="profile">
    <h1>Your Profile</h1>
    <ProfileBox />
  </div>
</template>

<style lang="stylus">

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ProfileBox from '@/components/ProfileBox.vue';

@Options({
  components: {
    ProfileBox,
  },
})
export default class Profile extends Vue {}
</script>
