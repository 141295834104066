<template>
  <div class="profile-box">
    <div class="user-details" v-if="user">
      <div class="user-image">
        <img :src="user.photoURL">
      </div>
      <div class="user-fields">
        <ul>
          <li><strong>Name</strong>: {{user.displayName}}</li>
          <li><strong>Email</strong>: {{user.email}}</li>
        </ul>

        <div class="launchpad-login">
          <h3>Launchpad Login</h3>
          <div class="generate-button">
            <button class="generate-button" type="button"
              @click="generateToken">Generate Token</button>
          </div>

          <div class="token-box">
            <input class="token-input" v-model="token" disabled v-show="token">

            <button class="copy-button" type="button"
                v-clipboard:copy="token"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-show="token">Copy Token</button>
          </div>

          <div class="instructions">
            <h4 class="instructions">Instructions</h4>
            <ol>
              <li>Click <strong>Generate Token</strong></li>
              <li>Once the token appears, click <strong>Copy Token</strong></li>
              <li>Paste the token into the Login window in Launchpad</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .profile-box

  // .launchpad-login
  //   display: flex;
  //   flex-direction: column;
  //   max-width: 50em;

  .question
    margin-top 4em;

    .user-image
      margin-top 25px;
      margin-left 10px;

      img
        width 75px;
        height 75px;
        border-radius 50%;
        border 4px solid #333;

  ul
    padding-left 0;

    li
      list-style-type none;

</style>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { getAuth, User } from 'firebase/auth';

export default class ProfileBox extends Vue {
  user: User|null = null;

  token: string|null = null;

  refreshToken: string|null = null;

  toast = useToast();

  generateToken() {
    this.token = this.refreshToken;
  }

  onCopy() {
    this.toast.success('Copied token');
  }

  onError() {
    this.toast.error('Failed to copy token');
  }

  created() {
    getAuth(this.$firebase).onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.refreshToken = user.refreshToken;
      }
    });
  }
}
</script>
